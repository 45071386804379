import { Row, Col } from '@advanza/grid'
import { Divider, PreIcon, LoadingDots } from '@advanza/ui'
import CoverageServiceOptions from 'components/serviceInfo/CoverageServiceOptions'
import React from 'react'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'
import CheckButton from '../CheckButton'
import LinearLoader from '../LinearLoader'
import Balloon from '../ui/Balloon'
import ServiceCompetitionGraph from './ServiceCompetitionGraph'
import style from './ServiceCompetitionStats.module.css'

class ServiceCompetitionStats extends React.Component {
    constructor(props) {
        super(props)
        this._changeOptions = this._changeOptions.bind(this)
        this.onChangeToggleMatchable = this.onChangeToggleMatchable.bind(this)
        this._fetchStats = this._fetchStats.bind(this)
    }

    componentDidMount() {
        this._fetchStats()
    }

    _fetchStats() {
        const { fetchService, fetchStats, serviceId } = this.props
        fetchStats(serviceId, 'competitionStats')
        fetchService(serviceId)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.serviceId !== this.props.serviceId) {
            this._fetchStats()
        }
    }

    _changeOptions(options) {
        const { serviceId, changeOptions } = this.props
        changeOptions(serviceId, 'competitionStats', { ...options, didInvalidate: true })
        this._fetchStats()
    }

    onChangeToggleMatchable(e) {
        this._changeOptions({ matchable: parseInt(e.target.value, 10) })
    }

    render() {
        const { serviceId, service, getStatsAndOptions } = this.props
        const { stats, options } = getStatsAndOptions('competitionStats')
        if (!service || !service.questions || !stats) {
            return <div className={style.skeleton}></div>
        }
        const { isFetching, matchable } = options
        const helpMessage = matchable
            ? 'Pros that can be matched at this moment'
            : 'Excludes empty/inactive/deleted subscriptions'
        return (
            <div>
                <h3>{service && service.name} Competition</h3>
                <Divider />
                <Row>
                    <Col x>
                        <Balloon content={helpMessage}>
                            <PreIcon icon={isFetching ? <LoadingDots /> : <span />}>
                                <span className="score">{stats.nrPros}</span> Available Pros
                            </PreIcon>
                        </Balloon>
                    </Col>
                    <Col x>
                        <CheckButton
                            onChange={this.onChangeToggleMatchable}
                            msg={<PreIcon icon="play_arrow">Matchable</PreIcon>}
                            checked={options.matchable}
                            value={options.matchable ? 0 : 1}
                        />
                    </Col>
                    <Col x>
                        <CheckButton
                            checked={options.subscribed}
                            msg={<PreIcon icon="workspace_premium">Premium</PreIcon>}
                            onChange={() =>
                                this._changeOptions({ subscribed: options.subscribed ? '' : '1' })
                            }
                        />
                    </Col>
                    <Col x>
                        <CheckButton
                            checked={options.showHidden}
                            msg={<PreIcon icon="visibility">Show hidden</PreIcon>}
                            onChange={() =>
                                this._changeOptions({ showHidden: options.showHidden ? '' : '1' })
                            }
                        />
                    </Col>
                </Row>
                <Divider m />
                <Row>
                    <Col x>
                        <ServiceCompetitionGraph
                            serviceId={serviceId}
                            onMapReady={() => {
                                this.setState({ mapReady: true })
                            }}
                        />
                    </Col>
                    <Col x>
                        <CoverageServiceOptions
                            type={'competitionStats'}
                            serviceId={serviceId}
                            onChangeValue={(optionIds) =>
                                this._changeOptions({
                                    optionIds,
                                })
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default ServiceStatsContainer(ServiceCompetitionStats)
